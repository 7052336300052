import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow';
import ClientOAuth2 from 'client-oauth2';
import styles from './style.module.scss';
import { Grid } from '@mui/material';
import Loader from '../loader';

const oauthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_ACCESS_URL,
    authorizationUri: process.env.REACT_APP_AUTH_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL + '/login',
    scopes: ['read'],
});

interface IRefreshProps {
    resumeUrl: string;
}

const Refresh: React.FunctionComponent<IRefreshProps> = ({ resumeUrl }) => {
    const state = {};
    state['resumeurl'] = resumeUrl;

    const logIn = (url: string): string => {
        setTimeout(() => {
            window.location.href = url;
        }, 100);
        return 'Refreshing';
    };

    return (
        <Grid container direction="column" alignItems="center" className={styles.root}>
            <RequestAuthorizationCode
                oauthClient={oauthClient}
                state={state}
                render={({ url }) => {
                    <div>
                        {logIn(url)} {'Refreshing'}
                    </div>;
                    return null;
                }}
            />
            <Loader appear={true} />
        </Grid>
    );
};

export default Refresh;
