import { useState } from 'react';
import { Button, Grid } from '@mui/material';
import { AuthorizationCodeCallback } from 'react-oauth2-auth-code-flow';
import ClientOAuth2 from 'client-oauth2';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { CircularProgress } from '@mui/material';
import { ITokenClientOauth2 } from '../../model/common/token/token-client-oauth2';
import { ICustomJwtPayload } from '../../context/useAuthProvider';
import { TokenHelper } from '../../utils/token-helper';
import { useAuth } from '../../hooks/useAuth';
import { getLandingPageByRole } from '../../helpers/helpers';
import { Replay } from '@mui/icons-material';
import styles from './style.module.scss';

interface ILoginProps {}

const oauthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_ACCESS_URL,
    authorizationUri: process.env.REACT_APP_AUTH_URL,
    redirectUri: process.env.REACT_APP_REDIRECT_URL + '/login',
    scopes: ['read'],
});

const Login: React.FunctionComponent<ILoginProps> = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [showLoader, setShowLoader] = useState(true);

    const handleSuccess = async (accessToken: ITokenClientOauth2, stateWrapper: { state: any }) => {
        const token: any = {
            accessToken: accessToken.accessToken,
            expires: accessToken.expires,
            expiresIn: accessToken.data.expires_in,
            refreshExpires: new Date(Date.now() + accessToken.data.refresh_expires_in * 1000),
            refreshExpiresIn: accessToken.data.refresh_expires_in,
            refreshToken: accessToken.refreshToken,
        };
        const decodedToken: ICustomJwtPayload = jwt_decode(accessToken.accessToken);
        const username: string = TokenHelper.getUsernameFromToken(decodedToken);
        const roles: string[] = TokenHelper.getUserRolesFromToken(decodedToken);

        await auth.handleLoginSuccess(token, username, roles);

        if (stateWrapper.state.resumeurl) {
            return navigate(stateWrapper.state.resumeurl, { replace: true });
        }
        navigate(getLandingPageByRole(roles), { replace: true });
    };

    const handleError = (error) => {
        setShowLoader(false);
        console.error('An error occurred', error.message);
        console.error(error);
    };

    const handleTryAgain = () => {
        navigate('/');
    };

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.mainDiv}>
            {showLoader && <CircularProgress color="primary" />}
            <AuthorizationCodeCallback
                oauthClient={oauthClient}
                onAuthSuccess={handleSuccess}
                onAuthError={handleError}
                render={({ processing, error }) => (
                    <div>
                        {processing && <p>Authorizing now...</p>}
                        {error && (
                            <>
                                <p className={styles.error}>An error occurred: {error.message}</p>
                                <Grid container direction="row" alignItems="center" justifyContent="center">
                                    <Button variant="outlined" size="large" color="secondary" className={styles.retryBtn} onClick={handleTryAgain}>
                                        Try again
                                        <Replay style={{ marginLeft: '0.5rem' }} />
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </div>
                )}
            />
        </Grid>
    );
};

export default Login;
