import { useEffect, useState } from 'react';
import ErrorSnackbar from '../errorSnackbar';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    LinearProgress,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Pagination } from '@mui/lab';
import TableRowProcess from './tableRow';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../store';
import { clearError, getProcesses } from '../../slices/processMonitor';
import styles from './style.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';

const cellWidth = {
    info: '80px',
    sessionId: '260px',
    date: '150px',
    icons: '120px',
};

const ROWS_PER_PAGE_ARRAY = [5, 10, 20, 50, 100];

interface Props {}

const Processes: React.FC<Props> = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { processes, isLoading, error, total } = useSelector((state) => state.processMonitor);
    const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('day'));
    const [finishDate, setFinishDate] = useState<Dayjs>(dayjs().endOf('day'));
    const [searchTerm, setSearchTerm] = useState('');
    const [showOnlyErrors, setShowOnlyErrors] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pagesCount, setPagesCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch(getProcesses((currentPage - 1) * rowsPerPage, rowsPerPage, startDate.toISOString(), finishDate.toISOString(), showOnlyErrors, searchTerm));
        if (total) {
            setPagesCount(Math.ceil(total / rowsPerPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, rowsPerPage, startDate, finishDate, showOnlyErrors, searchTerm]);

    if (error) {
        setTimeout(() => dispatch(clearError()), 4000);
    }

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setRowsPerPage(event.target.value as number);
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(dayjs(date).startOf('day'));
    };

    const handleFinishDateChange = (date: Dayjs | null) => {
        setFinishDate(dayjs(date).startOf('day'));
    };

    const handleShowOnlyErrors = () => {
        setShowOnlyErrors(!showOnlyErrors);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setCurrentPage(value);
    };

    return (
        <div className={styles.root3}>
            {error && <ErrorSnackbar message={error} />}
            <Grid container direction="row" alignItems="center" justifyContent="space-around" className={styles.flexWrap}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label={t('requests_from')}
                        inputFormat="DD.MM.YYYY"
                        value={startDate}
                        onChange={handleStartDateChange}
                        maxDate={finishDate}
                        className={styles.filter}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                        label={t('requests_to')}
                        inputFormat="DD.MM.YYYY"
                        value={finishDate}
                        onChange={handleFinishDateChange}
                        minDate={startDate}
                        className={styles.filter}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <TextField
                    name="search"
                    onChange={handleSearchTermChange}
                    type="text"
                    label={t('requests_search')}
                    value={searchTerm}
                    className={styles.filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControlLabel
                    control={<Checkbox checked={showOnlyErrors} onChange={handleShowOnlyErrors} name="checkedA" />}
                    label={t('processes_erroronly')}
                    className={styles.filter}
                />
                <Pagination count={pagesCount} page={currentPage} onChange={handlePageChange} color="primary" className={styles.filter} />
                <FormControl variant="outlined" className={styles.filter}>
                    <TextField select label={t('grid_rowsperpage')} value={rowsPerPage} onChange={handleRowsPerPageChange}>
                        {ROWS_PER_PAGE_ARRAY.map((rowsPerPage, i) => (
                            <MenuItem key={i} value={rowsPerPage}>
                                {rowsPerPage}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <TableContainer component={Paper} elevation={3} className={styles.tableContainer}>
                {isLoading && <LinearProgress color="primary" />}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" width={cellWidth.info}>
                                Info
                            </TableCell>
                            <TableCell align="center" width={cellWidth.sessionId}>
                                ID
                            </TableCell>
                            <TableCell align="right" width={cellWidth.date}>
                                {t('date')}
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                TOS Service
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                Identyum Service
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                Blacklist Checker
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                PSD2 Service
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                AML Questionnaire
                            </TableCell>
                            <TableCell align="center" width={cellWidth.icons}>
                                Loan Configurator
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {processes?.map((process, i) => (
                            <TableRowProcess key={i} proc={process} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default Processes;
