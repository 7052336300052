import { ChangeEvent, useEffect, useState } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert, AlertProps } from '@mui/lab';
import {
    FormControl,
    TextField,
    CircularProgress,
    Snackbar,
    SnackbarOrigin,
    FormControlLabel,
    FormGroup,
    Box,
    CardContent,
    CardHeader,
    Typography,
    Button,
    Switch,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../store';
import { createGroup, setAlert } from '../../slices/tosController';
import { ArrowBackIos } from '@mui/icons-material';
import styles from './style.module.scss';

function MuiAlert(props: AlertProps) {
    return <Alert elevation={6} variant="filled" {...props} />;
}

interface IEditConsentGroupProps {
    back: (dirty?: any) => void;
}

const EditConsentGroup: React.FunctionComponent<IEditConsentGroupProps> = ({ back }) => {
    const dispatch = useDispatch();
    const { alert } = useSelector((state) => state.tosController);
    const { t } = useTranslation();
    const snackbarOrigin: SnackbarOrigin = { horizontal: 'left', vertical: 'bottom' } as SnackbarOrigin;
    const [state, setState] = useState({
        enabled: false,
        required: false,
    });
    const [file, setFile] = useState<any>([]);
    const [upload, setUpload] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [fileBase64, setFileBase64] = useState<string>('');
    const [consentLabel, setConsentLabel] = useState<string>('');

    useEffect(() => {
        if (file.length) {
            const toBase64 = (_file: any) =>
                new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(_file);
                    reader.onload = () => resolve((reader.result as string)!.substring(28));
                    reader.onerror = (error) => reject(error);
                });

            const pdfToBase64 = async () => {
                let base64file = await toBase64(file[0]);
                setFileBase64(base64file as string);
            };
            pdfToBase64();
        }

        if (file.length === 0 || consentLabel.length === 0) {
            setUpload(false);
        }
        if (file.length && consentLabel.length > 0) {
            setUpload(true);
        }
    }, [file, consentLabel]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleDropzone = (files: any) => {
        setFile(files);
    };

    const handleCloseAlert = () => {
        dispatch(setAlert({ open: false, message: '', severity: 'success' }));
    };

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        setConsentLabel(event.target.value);
    };

    const handleUpload = () => {
        setAlert({ ...alert, open: false });
        setUploading(true);
        let _data = {
            label: consentLabel,
            enabled: state.enabled,
            required: state.required,
            pdfBase64Content: fileBase64,
        };
        dispatch(createGroup(_data));
    };

    return (
        <div className={styles.mainContainer}>
            <Box>
                <Button
                    className={styles.backBtn}
                    variant="contained"
                    onClick={() => {
                        back();
                    }}>
                    <ArrowBackIos /> {t('back_button')}
                </Button>
                <CardHeader title={t('consents_addgroup')} />
                <CardContent>
                    <FormControl style={{ width: '50%', paddingBottom: '20px' }}>
                        <TextField error={false} id="consent-label-helper-text" label={t('name')} variant="filled" required onChange={onChangeInput} />
                    </FormControl>
                    <FormGroup style={{ width: 'min-content', paddingBottom: '20px' }}>
                        <FormControlLabel
                            control={<Switch checked={state.enabled} color="primary" onChange={handleChange} name="enabled" />}
                            label={t('active')}
                        />
                        <FormControlLabel
                            control={<Switch checked={state.required} color="primary" onChange={handleChange} name="required" />}
                            label={t('required')}
                        />
                    </FormGroup>
                    <Typography variant="h6">{t('consents_uploaddoc')}</Typography>
                    <div className={styles.dropZone}>
                        <DropzoneArea
                            filesLimit={1}
                            previewText={t('dragndrop_text')}
                            dropzoneText={t('dragndrop_text')}
                            onChange={handleDropzone}
                            dropzoneParagraphClass={styles.dropZoneText}
                            acceptedFiles={['.pdf']}
                            showFileNames
                        />
                    </div>
                    {upload && !uploading && (
                        <Button className={styles.uploadBtn} color="secondary" variant="contained" onClick={handleUpload}>
                            {t('send_button')}
                        </Button>
                    )}
                    {upload && uploading && (
                        <Button className={styles.uploadBtn} disabled color="secondary" variant="contained">
                            {t('sending')}
                            <CircularProgress color="primary" />
                        </Button>
                    )}
                    {!upload && !uploading && (
                        <Button disabled className={styles.uploadBtn} color="secondary" variant="contained">
                            {t('send_button')}
                        </Button>
                    )}
                </CardContent>
            </Box>
            <Snackbar open={alert.open} anchorOrigin={snackbarOrigin} autoHideDuration={2000} onClose={handleCloseAlert}>
                <MuiAlert onClose={handleCloseAlert} severity={alert.severity}>
                    {alert.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default EditConsentGroup;
