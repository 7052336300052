import { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import { IConsentGroup, IConsentDocument } from '../../interfaces/CustomInterfaces';
import { formatDate } from '../../helpers/helpers';
import CustomPagination from '../TableOverrides/CustomPagination';
import { croLocale } from '../../helpers/dataGridLocales';
import {
    CardContent,
    CardHeader,
    Dialog,
    Grid,
    IconButton,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Switch,
    CircularProgress,
    Snackbar,
    SnackbarOrigin,
    Box,
} from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert, AlertProps, AlertColor } from '@mui/lab';
import { Description, ArrowBackIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useDispatch, useSelector } from '../../store';
import { clearError, closeConsentDialog, getConsentContent } from '../../slices/tosController';
import { useAuth } from '../../hooks/useAuth';
import { Roles } from '../../lib/constants/roles';
import styles from './style.module.scss';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

function MuiAlert(props: AlertProps) {
    return <Alert elevation={6} variant="filled" {...props} />;
}

interface IEditConsentGroupProps {
    group: IConsentGroup;
    back: (dirty?: boolean) => void;
}

const EditConsentGroup: React.FunctionComponent<IEditConsentGroupProps> = ({ group, back }) => {
    const dispatch = useDispatch();
    const { pdfBase64Content, error, consentDialogOpen } = useSelector((state) => state.tosController);
    const { t, i18n } = useTranslation();
    const { token, userRoles } = useAuth();

    const snackbarOrigin: SnackbarOrigin = { horizontal: 'left', vertical: 'bottom' } as SnackbarOrigin;

    const [state, setState] = useState({
        enabled: group.enabled,
        required: group.required,
    });
    const [alert, setAlert] = useState<{ open: boolean; message: string; severity: AlertColor }>({
        open: false,
        message: '',
        severity: 'success',
    });
    const [dirty, setDirty] = useState<boolean>(false);
    const [file, setFile] = useState<any>([]);
    const [uploading, setUploading] = useState<boolean>(false);
    const [fileBase64, setFileBase64] = useState<string>('');
    const [documentList, setDocumentList] = useState<IConsentDocument[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [numPages, setNumPages] = useState(null);
    const [pageToShow, setPageToShow] = useState(1);

    useEffect(() => {
        setDocumentList(group.documents);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (file.length) {
            const toBase64 = (_file: any) =>
                new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(_file);
                    reader.onload = () => resolve((reader.result as string)!.substring(28));
                    reader.onerror = (error) => reject(error);
                });

            const pdfToBase64 = async () => {
                let base64file = await toBase64(file[0]);
                setFileBase64(base64file as string);
            };
            pdfToBase64();
        }
    }, [file]);

    useEffect(() => {
        dirty && updateGroupParams();
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

    if (error) {
        setTimeout(() => dispatch(clearError()), 4000);
    }

    const handleClose = () => {
        dispatch(closeConsentDialog());
    };

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setDirty(true);
    };

    const updateDocumentList = () => {
        axios
            .get(`${process.env.REACT_APP_KENT_TOS_URL}/api/v1/groups`, {
                headers: { Authorization: `Bearer ${token?.accessToken}` },
            })
            .then((response) => {
                let detail = response.data.groups.filter((el) => {
                    return el.id === group.id;
                })[0];
                setDocumentList(detail.documents as IConsentDocument[]);
                return detail;
            })
            .catch((err) => {
                console.error('error', err);
            });
    };

    const updateGroupParams = async () => {
        let _data = {
            enabled: state.enabled,
            required: state.required,
        };
        axios
            .patch(`${process.env.REACT_APP_KENT_TOS_URL}/api/v1/groups/${group.id}`, JSON.stringify(_data), {
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token?.accessToken}` },
            })
            .then((success) => {
                setAlert({ ...alert, open: false });
                if (success.status >= 200 && success.status < 300) {
                    setAlert({ ...alert, open: true, message: 'Spremljeno!', severity: 'success' });
                }
                if (success.status >= 400) {
                    setAlert({ ...alert, open: true, message: 'Neusješno ažuriranje!', severity: 'error' });
                }
            })
            .catch((err) => {
                console.error('error', err);
            });
    };

    const handleDropzone = (files) => {
        setFile(files);
    };

    const handleCloseAlert = () => {
        setAlert({ ...alert, open: false });
    };

    const handleUpload = () => {
        setAlert({ ...alert, open: false });
        setUploading(true);
        setDirty(true);
        let _data = {
            active: true,
            pdfBase64Content: fileBase64,
        };
        axios({
            url: `${process.env.REACT_APP_KENT_TOS_URL}/api/v1/groups/${group.id}/documents`,
            method: 'POST',
            headers: { Authorization: `Bearer ${token?.accessToken}`, 'Content-Type': 'application/json' },
            data: JSON.stringify(_data),
        })
            .then(() => {
                setUploading(false);
                setFile([]);
                updateDocumentList();
                setAlert({ ...alert, open: true, message: 'Dokument spremljen!', severity: 'success' });
            })
            .catch(() => {
                setUploading(false);
                setAlert({ ...alert, open: true, message: 'Neuspješno slanje dokumenta!', severity: 'error' });
            });
    };

    const changeActiveDocument = async (id) => {
        setDirty(true);
        axios
            .patch(`${process.env.REACT_APP_KENT_TOS_URL}/api/v1/groups/${group.id}/documents/${id}`, {
                headers: {
                    Authorization: `Bearer ${token?.accessToken}`,
                },
            })
            .then((success) => {
                setAlert({ ...alert, open: false });
                if (success.status >= 200 && success.status < 300) {
                    setAlert({ ...alert, open: true, message: 'Spremljeno!', severity: 'success' });
                    updateDocumentList();
                }
                if (success.status >= 400) {
                    setAlert({ ...alert, open: true, message: 'Neuspješno ažuriranje!', severity: 'error' });
                }
            })
            .catch(() => {
                setAlert({ ...alert, open: true, message: 'Neuspješno ažuriranje!', severity: 'error' });
            });
    };

    const consentClickHandler = (consent: string) => {
        dispatch(getConsentContent(consent));
    };

    const getFormatedDate = (date: string) => {
        return formatDate(new Date(date));
    };

    const handlePageSizeChange = (num: number) => {
        setPageSize(num);
    };

    const widths = {
        preview: 60,
        updated: 180,
        created: 180,
        edit: 110,
    };
    const headers = {
        updated: `${t('last_change')}`,
        created: `${t('requests_grid_created')}`,
    };
    const columns: GridColDef[] = [
        {
            field: '',
            headerName: '',
            width: widths.preview,
            filterable: false,
            renderCell: (params) => {
                const onClick = () => {
                    consentClickHandler(params.row.id);
                };
                return (
                    <strong>
                        <IconButton color="secondary" component="span" onClick={onClick}>
                            <Description />
                        </IconButton>
                    </strong>
                );
            },
        },
        {
            field: 'updatedAt',
            headerName: headers.updated,
            width: widths.updated,
            filterable: false,
            valueGetter: (params) => {
                return new Date(params.row.createdAt);
            },
            renderCell: (params) => {
                return params.row.active ? <strong>{getFormatedDate(params.row.updatedAt)}</strong> : <p>{getFormatedDate(params.row.updatedAt)}</p>;
            },
        },
        {
            field: 'createdAt',
            headerName: headers.created,
            width: widths.created,
            filterable: false,
            valueGetter: (params) => {
                return new Date(params.row.createdAt);
            },
            renderCell: (params) => {
                return params.row.active ? <strong>{getFormatedDate(params.row.createdAt)}</strong> : <p>{getFormatedDate(params.row.createdAt)}</p>;
            },
        },
        {
            field: 'edit',
            headerName: ' ',
            width: widths.edit,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const onClick = () => {
                    changeActiveDocument(params.row.id);
                };
                return !params.row.active ? (
                    <strong>
                        <Button color="secondary" onClick={onClick} disabled={!userRoles.includes(Roles.termsWrite)}>
                            {t('activate')}
                        </Button>
                    </strong>
                ) : (
                    <strong>
                        <Button style={{ color: 'green' }} disabled>
                            {t('active')}
                        </Button>
                    </strong>
                );
            },
        },
    ];

    return (
        <div className={styles.mainContainer}>
            <Box>
                <Button
                    className={styles.backBtn}
                    variant="contained"
                    onClick={() => {
                        back(dirty);
                    }}>
                    <ArrowBackIos /> {t('back_button')}
                </Button>
                <CardHeader title={`${group.label}`} subheader={`${t('lastedited')} ${formatDate(new Date(group.updatedAt))}`} />
                <CardContent>
                    <FormGroup style={{ width: 'min-content', paddingBottom: '20px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.enabled}
                                    color="primary"
                                    onChange={handleChange}
                                    disabled={!userRoles.includes(Roles.termsWrite)}
                                    name="enabled"
                                />
                            }
                            label={t('active')}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.required}
                                    color="primary"
                                    onChange={handleChange}
                                    disabled={!userRoles.includes(Roles.termsWrite)}
                                    name="required"
                                />
                            }
                            label={t('required')}
                        />
                    </FormGroup>
                    {userRoles.includes(Roles.termsWrite) && (
                        <Grid>
                            <Typography variant="h6">{t('addnewdoc')}</Typography>
                            <div className={styles.dropZone}>
                                <DropzoneArea
                                    filesLimit={1}
                                    previewText={t('dragndrop_text')}
                                    dropzoneText={t('dragndrop_text')}
                                    onChange={handleDropzone}
                                    dropzoneParagraphClass={styles.dropZoneText}
                                    acceptedFiles={['.pdf']}
                                    showFileNames
                                />
                            </div>

                            {file.length > 0 && !uploading && (
                                <Button className={styles.uploadBtn} color="secondary" variant="contained" onClick={handleUpload}>
                                    {t('send_button')}
                                </Button>
                            )}
                            {file.length > 0 && uploading && (
                                <Button className={styles.uploadBtn} disabled color="secondary" variant="contained">
                                    {t('sending')}
                                    <CircularProgress color="primary" />
                                </Button>
                            )}
                            {file.length === 0 && !uploading && (
                                <Button disabled className={styles.uploadBtn} color="secondary" variant="contained">
                                    {t('send_button')}
                                </Button>
                            )}
                        </Grid>
                    )}
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            autoHeight
                            disableSelectionOnClick
                            disableColumnMenu
                            disableColumnSelector
                            disableColumnFilter
                            disableDensitySelector
                            pageSize={pageSize}
                            onPageSizeChange={handlePageSizeChange}
                            rowsPerPageOptions={[5, 10, 20, 50]}
                            pagination
                            rows={documentList}
                            columns={columns}
                            localeText={i18n.language === 'hr' ? croLocale : undefined}
                            components={{
                                Pagination: CustomPagination,
                            }}
                        />
                    </div>
                </CardContent>
                <Dialog onClose={handleClose} open={consentDialogOpen} maxWidth="md" className={styles.dialog}>
                    <Document file={`data:application/pdf;base64,${pdfBase64Content}`} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageToShow} />
                    </Document>
                    <Grid container alignItems="center" className={styles.buttonGroupPages}>
                        <Button className={styles.buttonsPages} disabled={pageToShow === 1} onClick={() => setPageToShow((pageToShow) => pageToShow - 1)}>
                            -
                        </Button>
                        {pageToShow} {t('pdfpage')} {numPages}
                        <Button
                            className={styles.buttonsPages}
                            disabled={pageToShow === numPages}
                            onClick={() => setPageToShow((pageToShow) => pageToShow + 1)}>
                            +
                        </Button>
                    </Grid>
                </Dialog>
            </Box>
            <Snackbar open={alert.open} anchorOrigin={snackbarOrigin} autoHideDuration={2000} onClose={handleCloseAlert}>
                <MuiAlert onClose={handleCloseAlert} severity={alert.severity}>
                    {alert.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default EditConsentGroup;
