import { useEffect, useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { getLandingPageByRole } from '../../helpers/helpers';
import styles from './style.module.scss';

interface IPrivateRouteProps extends RouteProps {
    role: string;
    name?: string;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({ children, role }) => {
    const { userRoles } = useAuth();
    const navigate = useNavigate();

    const [showLoader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        if (!userRoles.includes(role)) {
            let landingPage = getLandingPageByRole(userRoles);
            navigate(landingPage, { replace: true });
            return;
        }
        setShowLoader(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {showLoader && (
                <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.mainDiv}>
                    {showLoader && <CircularProgress />}
                </Grid>
            )}
            {!showLoader && children}
        </>
    );
};

export default PrivateRoute;
