import { RouteObject } from 'react-router';
import DashboardLayout from './components/dashboard/DashboardLayout';
import RequestOverview from './components/RequestOverview/RequestOverview';
import Refresh from './components/Login/Refresh';
import Login from './components/Login/Login';
import OrderInfo from './components/RequestOverview/OrderInfo';
import Processes from './components/Processes';
import ConsentOverview from './components/Consents/ConsentOverview';
import NoPrivileges from './components/Login/NoPrivileges';
import AuthGuard from './components/auth-guard/AuthGuard';
import PrivateRoute from './components/auth-guard/PrivateRoute';
import { Roles } from './lib/constants/roles';

const OVERVIEW_ROUTE: RouteObject = {
    path: 'overview',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/overview',
            element: (
                <PrivateRoute role={Roles.requestView}>
                    <RequestOverview />
                </PrivateRoute>
            ),
        },
        {
            path: '/overview/orderinfo',
            element: <OrderInfo />,
        },
    ],
};

const PROCESSES_ROUTE: RouteObject = {
    path: 'processes',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/processes',
            element: (
                <PrivateRoute role={Roles.processMonitor}>
                    <Processes />
                </PrivateRoute>
            ),
        },
    ],
};

const CONSENTS_ROUTE: RouteObject = {
    path: 'consents',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/consents',
            element: (
                <PrivateRoute role={Roles.termsRead}>
                    <ConsentOverview />
                </PrivateRoute>
            ),
        },
    ],
};

const REFRESH_ROUTE: RouteObject = {
    path: 'refresh',
    element: <Refresh resumeUrl="" />,
};

const LOGIN_ROUTE: RouteObject = {
    path: 'login',
    element: <Login />,
};

const NO_PRIVILEGES_ROUTE: RouteObject = {
    path: 'no-privileges',
    element: <NoPrivileges />,
};

const HOME_ROUTE: RouteObject = {
    path: '/',
    element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Refresh resumeUrl="" />,
        },
    ],
};

const routes: RouteObject[] = [HOME_ROUTE, PROCESSES_ROUTE, CONSENTS_ROUTE, NO_PRIVILEGES_ROUTE, REFRESH_ROUTE, LOGIN_ROUTE, OVERVIEW_ROUTE];

export default routes;
