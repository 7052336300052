import { Button, Chip, Dialog, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ConsentDescription } from 'kent-process-monitor-api';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from '../../../store';
import { getConsentContent } from '../../../slices/tosController';
import { getTosData } from '../../../slices/dataController';
import styles from './style.module.scss';

interface Props {
    sessionId: string;
    isExpanded: boolean;
}

const TOSService: React.FC<Props> = ({ sessionId, isExpanded }) => {
    const dispatch = useDispatch();
    const { pdfBase64Content } = useSelector((state) => state.tosController);
    const { tosData } = useSelector((state) => state.dataController);
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(null);
    const [pageToShow, setPageToShow] = useState(1);
    const [open, setIsOpen] = useState(false);

    useEffect(() => {
        if (isExpanded) {
            dispatch(getTosData(sessionId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    const handleClose = () => {
        setIsOpen(false);
    };

    const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
        setNumPages(nextNumPages);
    };

    const consentClickHandler = (consent: ConsentDescription) => {
        dispatch(getConsentContent(consent.id));
    };

    return (
        <>
            {t('tos_acceptedconsents')}
            {tosData?.acceptedConsents?.map((consent) => (
                <Chip
                    key={consent.id}
                    style={{ textDecoration: 'underline', cursor: 'pointer', margin: '0 1rem' }}
                    label={consent.name}
                    variant="outlined"
                    onClick={() => consentClickHandler(consent)}></Chip>
            ))}
            <Dialog onClose={handleClose} open={open} maxWidth="md" className={styles.dialog}>
                <Document file={`data:application/pdf;base64,${pdfBase64Content}`} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageToShow} />
                </Document>
                <Grid container alignItems="center" className={styles.buttonGroupPages}>
                    <Button className={styles.buttonsPages} disabled={pageToShow === 1} onClick={() => setPageToShow((pageToShow) => pageToShow - 1)}>
                        -
                    </Button>
                    {pageToShow} {t('pdfpage')} {numPages}
                    <Button className={styles.buttonsPages} disabled={pageToShow === numPages} onClick={() => setPageToShow((pageToShow) => pageToShow + 1)}>
                        +
                    </Button>
                </Grid>
            </Dialog>
        </>
    );
};

export default TOSService;
