import ReactDOM from 'react-dom';
import styles from './style.module.scss';
import { CircularProgress } from '@mui/material';

type Props = {
    appear?: boolean;
};

export const Loader = ({ appear = false }: Props) => {
    if (!appear) return null;
    let parent;
    parent = window?.document?.body;

    if (!parent) return null;

    const child = (
        <div className={styles.loaderMainDiv}>
            <div className={styles.loaderBgDiv} />
            <div className={styles.loaderInnerDiv}>
                <div className={`${styles.imgSize}`} style={{ position: 'relative' }}>
                    <CircularProgress color="primary" />
                </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(child, parent);
};

export default Loader;
